<template>
  <div class="mainPolicyContainer">
    <div class="headingContainer headingCenter">
      <span class="heading">Privacy Statement</span>
    </div>
    
      <p class="margin-left-align">
        This privacy policy document outlines the types of personal information
        is received and collected by AlgoJi Enterprises Pvt Ltd ("Algoji") and
        how it is used.
      </p>
      <p class="margin-left-align">
        At AlgoJi we are strongly committed to protecting the personal and
        financial information that you submit to us. This information is
        provided when you register to receive certain AlgoJi services or
        products, such as software and coding services. The personal information
        we get from you will help us provide you with improved services and
        products that match your needs as closely as possible.
      </p>
      <p class="margin-left-align">
        We, at AlgoJi, acknowledge and accept that the personal details that you
        impart to us, is to be kept in strict confidentiality and to use the
        information only in the manner which would be beneficial to our
        customers. We consider our relationship with you as invaluable and
        strive to respect and safeguard your right to privacy. We shall protect
        the personal details received from you with the same degree of care, but
        no less than a reasonable degree of care, to prevent the unauthorized
        use, dissemination, or publication of these information as we protect
        our own confidential information of a like nature. We shall use the
        personal information to improve our service to you and to keep you
        updated about our new product or information that may be of interest to
        you. The information collected from you would be used in the right
        spirit and context in which it is intended to be used. Your information
        would be used by us to process your trading request and to carry out the
        settlements of your obligations.
      </p>
      <p class="margin-left-align">
        We shall not sell your personal information to any third party in any
        case.
      </p>
      <p class="margin-left-align">
        However, we may be required to share your information under relevant law
        when mandated by a legal authority. In case of co-organized events
        (online or offline), our partners will have access user’s event specific
        data only. When signing up for events, you should consult the privacy
        policy of these third-parties.
      </p>
      <p class="margin-left-align">
        Do help us protect your privacy by maintaining the secrecy of the
        username and password you use for any of our services.
      </p>
    
    <div class="headingContainer subheader">
      Log Files
    </div>
    <p class="margin-left-align">
      The Customer shall defend, indemnify and hold Company and its employees
      and agents harmless from and against, any and all suits, actions and
      proceedings, claims, liabilities, losses, damages, costs and expenses,
      including reasonable outside attorneys’ fees, arising directly or
      indirectly in connection with:
    </p>
    <div class="headingContainer subheader">
      Cookies and Web Beacons
    </div>
    <p class="margin-left-align">
      Algoji may use cookies to store information about visitors preferences, record user-specific information on which pages the user access or visit, customize Web page content based on visitors browser type or other information that the visitor sends via their browser.
    </p>

  </div>
</template>
<script>
import "../assets/css/usage-policy.css";
export default {};
</script>

<style scoped>
.mainPolicyContainer {
  min-height: 100vh;
  padding-right: 25px;
  font-weight: 400;
}
.usage ol {
  margin: 0px 30px;
  font-family: Arial, serif;
  font-weight: 500;
  font-style: normal;
  text-decoration: none;
}

li {
  margin-bottom: 20px;
}

p {
  font-weight: 400;
}
</style>